import * as React from 'react'

import {linkBarText, linkBarLink, linkBarGeneral} from './styles/layout.module.css'

const LinkBar = ({children}) => {
    return(
        <div className={linkBarGeneral}>
            <p className={linkBarText}>
                {children}
            </p>
        </div>
    )
}

export default LinkBar
