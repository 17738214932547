import * as React from 'react'
import Layout from '../components/Layout'
import LinkBar from '../components/LinkBar'
import {Link, withPrefix} from 'gatsby'
import {Helmet} from "react-helmet";

//Global stylesheet
import "../components/styles/global.css"

const Intentional404 = () => {
    return(
        <Layout pageTitle={"Intentional 404"}>

            <Helmet>
                <meta charSet="UTF-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>

                <title>Under construction | Bangbiangyang Tourism</title>

                <meta name="author" content="aqq.xyz"/>
                <meta name="description" content="This page isn't completed yet."/>

                <meta property="og:title" content="Under construction | Bangbiangyang Tourism"/>
                <meta property="og:description" content="This page isn't completed yet."/>
                <meta property="og:url" content="bangbiangyang.aqq.xyz/Intentional404"/>
                <meta property="og:site_name" content="bangbiangyang.aqq.xyz"/>

                <meta name="robots" content="noindex"/>

                <link rel="icon" href="../static/favicon.ico" />
            </Helmet>

            <LinkBar>
                <Link to="/">Home</Link> > <Link to="Intentional404">Intentional 404</Link>
            </LinkBar>

            <div className="thinContentWrapper">
                <h2>
                    Under construction.
                </h2>
                <p>
                    This page doesn't currently exist.
                </p>
                <p>
                    <strong>This isn't a regular 404 (i.e. missing) page!</strong> The link you've clicked on isn't broken or misdirected, but the page it's supposed to link to
                    hasn't been completed yet.
                </p>
                <p>
                    Alternatively, the link may have been purely decorative and not intended for content.
                </p>
                <Link class="standardButton" to="/" style={{marginTop: 1 + 'rem'}}>Back to the homepage.</Link>
            </div>

        </Layout>
    )
}

export default Intentional404